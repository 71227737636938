import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "../services/conexaoFirebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const Context = createContext({});

export default function ContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function loadUser() {
      const storageUser = localStorage.getItem("@usuarios");

      if (storageUser) {
        setUser(JSON.parse(storageUser));

        const docRef = doc(db, "admins", JSON.parse(storageUser).uid);
        const docDados = await getDoc(docRef);

        if (docDados.exists()) {
          setLoading(false);
          navigate("/dash");
        } else {
          toast.error("Apenas administradores podem acessar esta página.");
          setLoading(false);
          navigate("/");
        }
      } else {
        setLoading(false);
      }
    }

    loadUser();
  }, []);

  // buscando todos os usuários
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);

        const usersData = usersSnapshot.docs.map((doc) => {
          const userData = {
            id: doc.id,
            ...doc.data(),
          };

          // Verifique se o campo 'formularios' está presente e preenchido
          if (!userData.formularios) {
            // Adicione lógica aqui para contar usuários que não preencheram o formulário
            // Por exemplo, você pode incrementar um contador no estado ou fazer o que for necessário
          }

          return userData;
        });

        setUsuarios(usersData);
      } catch (error) {
        toast.error("Erro ao obter usuários:", error);
      }
    };

    fetchData();
  }, []);

  async function logar(email, senha) {
    setLoadingAuth(true);

    await signInWithEmailAndPassword(auth, email, senha)
      .then(async (value) => {
        let uid = value.user.uid;

        const docRef = doc(db, "admins", uid);
        const docDados = await getDoc(docRef);

        if (docDados.exists()) {
          let data = {
            uid: uid,
            email: value.user.email,
          };

          setUser(data);
          storageUser(data);
          setLoadingAuth(false);
          toast.success("Bem-Vindo(a) de volta!");
          navigate("/dash");
        } else {
          toast.error("Apenas administradores podem fazer login.");
          setLoadingAuth(false);
        }
      })
      .catch((error) => {
        toast.error("Oops! Algo deu errado, " + error);
        console.log(error);
        setLoadingAuth(false);
      });
  }

  function storageUser(data) {
    localStorage.setItem("@usuarios", JSON.stringify(data));
  }

  async function logOut() {
    await signOut(auth)
      .then(() => {
        toast.success("Você foi desconectado com sucesso!");
        navigate("/");
      })
      .catch((error) => {
        toast.success(error);
      });
    localStorage.removeItem("@usuarios");
    setUser(null);
  }

  return (
    <Context.Provider
      value={{
        signed: !!user,
        user,
        setUser,
        logar,
        logOut,
        loadingAuth,
        storageUser,
        usuarios,
      }}
    >
      {children}
    </Context.Provider>
  );
}
