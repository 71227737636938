import React, { useState, useEffect } from "react";
import "./styles.css";

import { toast } from "react-toastify";
import ImagemPushNotification from "../../assets/imagem-push.png";

import ApiPush from "../../services/apiPush";
import LinksRoutes from "./components/rotas";
import Estados from "../../Data/estados";
import Plataformas from "../../Data/platform";

export default function PushByPlatformAndState() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [clickActionUrl, setClickActionUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [plataforma, setPlataforma] = useState("");
  const [estado, setEstado] = useState("");

  const handlePlataformaChange = (event) => {
    setPlataforma(event.target.value);
  };

  const handleEstadoChange = (event) => {
    setEstado(event.target.value);
  };

  const sendPushNotification = async () => {
    if (title === "" || body === "" || plataforma === "" || estado === "") {
      toast.warn(
        "Por favor, preencher título, descrição, plataforma, e estado!"
      );
      return;
    }

    if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;

      image.onload = function () {
        const imageSize = this.width * this.height;
        const imageSizeInKB = imageSize / 1024;

        if (imageSizeInKB > 300) {
          toast.error("Desculpe, a imagem não pode ser maior que 300KB.");
          return;
        }

        sendNotification();
      };

      image.onerror = function () {
        toast.error("Erro ao carregar a imagem.");
        return;
      };
    } else {
      sendNotification();
    }
  };

  const sendNotification = async () => {
    const data = {
      title: title,
      body: body,
      clickAction: clickActionUrl,
      platform: plataforma,
      estado: estado,
    };

    // Send the notification to all tokens
    ApiPush.post("/push-by-platform-and-state", data)
      .then((response) => {
        //console.log("Notificação enviada com sucesso!");
        toast.success("Notificação enviada com sucesso!");
        setTitle("");
        setBody("");
        setClickActionUrl("");
        setPlataforma("");
        setEstado("");
        // setImageUrl("");
      })
      .catch((error) => {
        console.error("Erro ao enviar notificação:", error);
        toast.error("Erro ao enviar notificação");
      });
  };

  return (
    <div className="scrollPush">
      <div className="areaBotaoDesconectar">
        <LinksRoutes />
      </div>

      <div className="container">
        <div className="col">
          <img
            src={ImagemPushNotification}
            alt="Imagem de notificação push"
            className="ImagemPushNotification"
          />

          <p className="imageDescricao">Push Notifications ease!</p>
        </div>
        <div className="colNoFlexCenter">
          <h2 className="title">Notificação por Sistema e Estado</h2>

          <div className="boxInput">
            <label>Título da notificação</label>
            <input
              type="text"
              value={title}
              placeholder="Insira o título da notificação"
              maxLength={140}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="boxInput">
            <label>Descrição da notificação</label>
            <input
              type="text"
              placeholder="Insira a descrição da notificação"
              value={body}
              maxLength={600}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
          <div className="boxInput">
            <label>Click Action URL (opcional)</label>
            <input
              type="text"
              placeholder="URL de redirecionamento"
              value={clickActionUrl}
              onChange={(e) => setClickActionUrl(e.target.value)}
            />
          </div>

          <div className="boxInput">
            <label>Plataforma</label>
            <select
              className="boxSelect"
              value={plataforma}
              onChange={handlePlataformaChange}
            >
              <option value="">Selecione uma plataforma</option>
              {Plataformas.plataformas.map((plataforma, index) => (
                <option key={index} value={plataforma}>
                  {plataforma}
                </option>
              ))}
            </select>
          </div>

          <div className="boxInput">
            <label>Estado</label>
            <select
              className="boxSelect"
              value={estado}
              onChange={handleEstadoChange}
            >
              <option value="">Selecione um estado</option>
              {Estados.estados.map((estado, index) => (
                <option key={index} value={estado}>
                  {estado}
                </option>
              ))}
            </select>
          </div>

          {/* 
          <div className="boxInput">
            <label>Imagem da notificação (opcional)</label>
            <input
              type="text"
              placeholder="https://www.sua-imagem.com/image.png"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
            />
          </div>
         */}

          <button onClick={sendPushNotification} className="buttonSendPush">
            Enviar
          </button>
          <br />
        </div>
      </div>
    </div>
  );
}
