import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ContextProvider from "../Context/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./private";
import Error from "../pages/Error";
import Login from "../pages/Login";

import Painel from "../pages/Painel";
import Dash from "../pages/Dash/Dash";
import Depoimentos from "../pages/Depoimentos/Depoimentos";
import DadosComProblemas from "../pages/DadosComProblemas/DadosComProblemas";
import NovaVersaoApp from "../pages/NovaVersaoApp/NovaVersaoApp";
import BuscarPessoas from "../pages/BuscarPessoas/BuscarPessoas";
import PushByEstado from "../pages/Painel/PushByEstado";
import PushByPlatformAndState from "../pages/Painel/PushByPlatformAndState";
import PushByPlatform from "../pages/Painel/PushByPlatform";

export default function RoutesApp() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <ToastContainer outClose={3000} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/notification-control-center"
            element={
              <Private>
                <Painel />
              </Private>
            }
          />
          <Route
            path="/push-by-estado"
            element={
              <Private>
                <PushByEstado />
              </Private>
            }
          />
          <Route
            path="/push-by-platform"
            element={
              <Private>
                <PushByPlatform />
              </Private>
            }
          />
          <Route
            path="/push-by-platform-and-state"
            element={
              <Private>
                <PushByPlatformAndState />
              </Private>
            }
          />
          <Route
            path="/dash"
            element={
              <Private>
                <Dash />
              </Private>
            }
          />
          <Route
            path="/depoimentos"
            element={
              <Private>
                <Depoimentos />
              </Private>
            }
          />
          <Route
            path="/buscar-pessoas"
            element={
              <Private>
                <BuscarPessoas />
              </Private>
            }
          />
          <Route
            path="/dados-com-problemas"
            element={
              <Private>
                <DadosComProblemas />
              </Private>
            }
          />
          <Route
            path="/nova-versao-aplicativo"
            element={
              <Private>
                <NovaVersaoApp />
              </Private>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </ContextProvider>
    </BrowserRouter>
  );
}
