import axios from "axios";

const apiKey = process.env.REACT_APP_KEY_X_API_KEY;
const baseURL = process.env.REACT_APP_BASE_URL_PUSH;

const ApiPush = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": apiKey,
    // Authorization: `Bearer ${token}`,
  },
});

export default ApiPush;
