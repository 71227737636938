import "./styles.css";
import ImageNotPhoto from "../../assets/notPhoto.png";
import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { format } from "date-fns";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { db } from "../../services/conexaoFirebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  return (
    <div className="paginacao">
      <div className="areaBotoes">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="botaoPaginacao"
        >
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="botaoPaginacao"
        >
          Próxima
        </button>
      </div>
    </div>
  );
};

export default function Depoimentos() {
  const [depoimentos, setDepoimentos] = useState([]);
  const [filtroAprovados, setFiltroAprovados] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const depoimentosPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const depoimentosCollection = collection(db, "depoimentos");
        const depoimentosSnapshot = await getDocs(depoimentosCollection);

        const depoimentosData = depoimentosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDepoimentos(depoimentosData);
      } catch (error) {
        toast.error("Erro ao obter depoimentos:", error);
      }
    };

    fetchData();
  }, []);

  const handleAprovar = async (depoimento) => {
    try {
      const depoimentoRef = doc(db, "depoimentos", depoimento.id);

      // Atualiza o campo 'approved' para o oposto do valor atual
      await updateDoc(depoimentoRef, {
        approved: !depoimento.approved,
      });

      // Atualiza a lista de depoimentos para refletir a mudança
      setDepoimentos((prevDepoimentos) =>
        prevDepoimentos.map((prevDepoimento) =>
          prevDepoimento.id === depoimento.id
            ? { ...prevDepoimento, approved: !depoimento.approved }
            : prevDepoimento
        )
      );
      toast.success("Depoimento aprovado com sucesso!");
    } catch (error) {
      toast.error("Erro ao aprovar depoimento");
      console.log("Erro ao aprovar depoimento", error);
    }
  };

  const handleDeletar = async (id) => {
    try {
      const depoimentoRef = doc(db, "depoimentos", id);
      await deleteDoc(depoimentoRef);

      // Atualiza a lista de depoimentos para refletir a exclusão
      setDepoimentos((prevDepoimentos) =>
        prevDepoimentos.filter((prevDepoimento) => prevDepoimento.id !== id)
      );
      toast.success("Depoimento deletado com sucesso!");
    } catch (error) {
      toast.error("Erro ao deletar depoimento:", error);
    }
  };

  // Movendo a declaração para este ponto
  const depoimentosFiltrados = depoimentos.filter(
    (depoimento) => depoimento.approved === filtroAprovados
  );

  // Atualize o cálculo do totalPages com base em depoimentosFiltrados
  const totalPages = Math.ceil(
    depoimentosFiltrados.length / depoimentosPerPage
  );

  const depoimentosFiltradosPaginados = depoimentosFiltrados.slice(
    (currentPage - 1) * depoimentosPerPage,
    currentPage * depoimentosPerPage
  );

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbordDash">
        <div className="depoimentos">
          {depoimentos.length === 0 ? (
            <h3>Nenhum depoimento encontrado!</h3>
          ) : (
            <h3>
              {depoimentos.length === 1
                ? `${depoimentos.length} Depoimento ao total`
                : `${depoimentos.length} Total de depoimentos `}
            </h3>
          )}

          <div className="boxSelectFilter">
            <select
              onChange={(e) => {
                setFiltroAprovados(e.target.value === "true");
                setCurrentPage(1);
              }}
              className="selectFilterDepoimentos"
            >
              <option value="false">Não aprovados</option>
              <option value="true">Aprovados</option>
            </select>
          </div>

          <div style={{ marginTop: 30 }}>
            {depoimentos.length === 0 ? (
              <h3>Nenhum depoimento encontrado!</h3>
            ) : (
              <>
                <h3>
                  {depoimentosFiltradosPaginados.length === 1
                    ? `${depoimentosFiltradosPaginados.length} Depoimento${
                        depoimentosFiltradosPaginados[0].approved
                          ? " aprovado"
                          : " não aprovado"
                      }`
                    : `${depoimentosFiltradosPaginados.length} Depoimentos${
                        depoimentosFiltradosPaginados[0].approved
                          ? " aprovados"
                          : " não aprovados"
                      } `}
                </h3>
              </>
            )}
          </div>

          {depoimentosFiltrados.length > depoimentosPerPage && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}

          {depoimentosFiltradosPaginados
            .filter((depoimento) => depoimento.approved === filtroAprovados)
            .slice()
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((depoimento) => (
              <div key={depoimento.id} className="cardDepoimento">
                <div className="colCard">
                  <div className="boxFotoPerfil">
                    <img
                      src={depoimento.photo || ImageNotPhoto}
                      alt="imagem de perfil"
                      className="imagemDePerfil"
                    />
                    <div>
                      <span>{depoimento.name}</span>

                      <div className="areaAvaliacaoStar">
                        {[...Array(depoimento.stars)].map((_, index) => (
                          <FaStar key={index} size={22} color="#f7d200" />
                        ))}
                      </div>
                      <span id="depoimentoData">
                        {" "}
                        {format(new Date(depoimento.createdAt), "dd/MM/yyyy")}
                      </span>
                    </div>
                  </div>

                  <div className="areaAvaliacao">
                    <p>{depoimento.comment}</p>
                  </div>
                </div>

                <div className="areaBotoes">
                  <button onClick={() => handleAprovar(depoimento)}>
                    {depoimento.approved ? "Esconder" : "Aprovar"}
                  </button>
                  <button
                    id="activeButton"
                    onClick={() => handleDeletar(depoimento.id)}
                  >
                    Deletar
                  </button>
                </div>
              </div>
            ))}

          {depoimentosFiltradosPaginados.length >= 4 && (
            <>
              {depoimentosFiltrados.length > depoimentosPerPage && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </>
          )}

          <br />
        </div>
      </main>
    </div>
  );
}
