import React from "react";
import { useNavigate } from "react-router-dom";

export default function NavigateSelect({ selectedOption, setSelectedOption }) {
  const navigate = useNavigate();

  const navigateToRoute = (selectedValue) => {
    switch (selectedValue) {
      case "default":
        // Redirecionar para a rota padrão, se necessário
        break;
      case "push_por_estado":
        navigate("/push-by-estado");
        break;
      case "push_por_sistema":
        navigate("/push-by-platform");
        break;
      case "push_por_sistema_e_estado":
        navigate("/push-by-platform-and-state");
        break;
      default:
        break;
    }
  };

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    navigateToRoute(selectedValue);
  };

  return (
    <select
      className="btnDesconectar"
      onChange={handleOptionChange}
      value={selectedOption}
    >
      <option value="default">Push para todos os tipos</option>
      <option value="push_por_estado">Push por estado</option>
      <option value="push_por_sistema">Push por sistema operacional</option>
      <option value="push_por_sistema_e_estado">
        Push por sistema e estado
      </option>
    </select>
  );
}
