import { Link } from "react-router-dom";
import "../styles.css";

export default function LinksRoutes() {
  return (
    <div style={{ display: "flex", gap: 10 }}>
      <Link to="/dash">
        <button className="btnDesconectar">Dashboard</button>
      </Link>
      <Link to="/notification-control-center">
        <button className="btnDesconectar">Central de notificação</button>
      </Link>
    </div>
  );
}
