import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDvMhbKkNdlzMXZcehwGT3auui_Qp0-RJ8",
  authDomain: "rede-publica.firebaseapp.com",
  databaseURL: "https://rede-publica-default-rtdb.firebaseio.com",
  projectId: "rede-publica",
  storageBucket: "rede-publica.appspot.com",
  messagingSenderId: "681080326633",
  appId: "1:681080326633:web:a939c74b4253bf55366378",
  measurementId: "G-ZZEERVJTKN",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
//const analytics = getAnalytics(app);

const getUsersCount = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    return querySnapshot.size;
  } catch (error) {
    console.error("Erro ao obter a lista de usuários:", error);
    return 0; // ou outra ação apropriada em caso de erro
  }
};

export { auth, db, storage, getUsersCount };
