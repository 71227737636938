import "./styles.css";
import ImageNotPhoto from "../../assets/notPhoto.png";
import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { format } from "date-fns";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { db } from "../../services/conexaoFirebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";

export default function DadosComProblemas() {
  const [dadosComProblemas, setDadosComProblemas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dadosComProblemasCollection = collection(db, "dadosComProblemas");
        const dadosComProblemasSnapshot = await getDocs(
          dadosComProblemasCollection
        );

        const dadosComProblemasData = dadosComProblemasSnapshot.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          })
        );

        setDadosComProblemas(dadosComProblemasData);
      } catch (error) {
        toast.error("Erro ao obter dados com problemas:", error);
      }
    };

    fetchData();
  }, []);

  // Restante do código permanece inalterado, apenas substituído 'depoimentos' por 'dadosComProblemas'

  const handleConcluir = async (dados) => {
    alert("Em breve a função!");
  };

  const handleDeletar = async (id) => {
    try {
      const dadosRef = doc(db, "dadosComProblemas", id);
      await deleteDoc(dadosRef);

      // Atualiza a lista de dados para refletir a exclusão
      setDadosComProblemas((prevDados) =>
        prevDados.filter((prevDado) => prevDado.id !== id)
      );
      toast.success("Dados deletados com sucesso!");
    } catch (error) {
      toast.error("Erro ao deletar dados:", error);
    }
  };

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbordDash">
        <div className="depoimentos">
          <div className="titleDepoimento">
            {dadosComProblemas.length === 0 ? (
              <h3>Nenhum dado com problema encontrado!</h3>
            ) : (
              <h3>
                {dadosComProblemas.length === 1
                  ? `${dadosComProblemas.length} Dado com Problema`
                  : `${dadosComProblemas.length} Dados com Problemas`}
              </h3>
            )}
          </div>
          {dadosComProblemas
            .sort((a, b) => {
              // Convertendo as strings de data para objetos Date
              const dateA = new Date(
                parseInt(a.data.split("/")[2]),
                parseInt(a.data.split("/")[1]) - 1,
                parseInt(a.data.split("/")[0])
              );
              const dateB = new Date(
                parseInt(b.data.split("/")[2]),
                parseInt(b.data.split("/")[1]) - 1,
                parseInt(b.data.split("/")[0])
              );

              // Ordenando com base nas datas
              return dateB - dateA; // Ordene em ordem decrescente (mais recente primeiro)
            })
            .map((dados) => (
              <div key={dados.id} className="cardDepoimento">
                <div className="colCard">
                  <div className="boxFotoPerfil">
                    <img
                      src={dados.photo || ImageNotPhoto}
                      alt="imagem de perfil"
                      className="imagemDePerfil"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                      }}
                    >
                      <span>{dados.nomeUsuario}</span>
                      <span id="depoimentoData">
                        {dados.data} | {dados.hora}
                      </span>
                    </div>
                  </div>

                  <div className="areaContent">
                    <p>
                      <strong>Estabelecimento:</strong>{" "}
                      {dados.estabelecimento && dados.estabelecimento}
                    </p>

                    <div className="boxErros">
                      <strong>Erros em:</strong>
                      <ul>
                        {dados.errosEm && dados.errosEm.length > 0 ? (
                          dados.errosEm.map((erro, index) => (
                            <li key={index}>{erro}</li>
                          ))
                        ) : (
                          <li>Nenhum erro registrado</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="areaBotoes">
                  <button onClick={() => handleConcluir(dados)}>
                    {dados.approved ? "Esconder" : "Aprovar"}
                  </button>
                  <button
                    id="activeButton"
                    onClick={() => handleDeletar(dados.id)}
                  >
                    Deletar
                  </button>
                </div>
              </div>
            ))}
        </div>
      </main>
    </div>
  );
}
