import "./styles.css";
import React, { useContext, useState, useEffect } from "react";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { Context } from "../../Context/Auth";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";

import { getUsersCount, db } from "../../services/conexaoFirebase";
import { collection, getDocs } from "firebase/firestore";

export default function Dash() {
  const { usuarios } = useContext(Context);
  const [userCount, setUserCount] = useState(0);
  const [quantideFormularioNaoPreenchido, setQuantideFormularioNaoPreenchido] =
    useState(0);

  const [usuariosPorEstado, setUsuariosPorEstado] = useState({});

  const [quantidadeErros, setQuantidadeErros] = useState(0);

  // Função para buscar a quantidade de usuários
  const fetchUserCount = async () => {
    try {
      const userRecords = await getUsersCount();
      const count = userRecords;
      setUserCount(count);
    } catch (error) {
      toast.error("Erro ao obter a quantidade de usuários:", error);
    }
  };

  // Função para buscar a quantidade de usuários que não preencheram o formulário
  const fetchQuantidadeFormulariosNaoPreenchidos = () => {
    const usersNotFilledCount = usuarios.filter(
      (user) => user.formularios
    ).length;
    setQuantideFormularioNaoPreenchido(usersNotFilledCount);
  };

  // Função para contar usuários por estado
  const countUsuariosPorEstado = () => {
    const usersByState = usuarios.reduce((acc, user) => {
      const estado = user.estado;

      if (estado) {
        acc[estado] = (acc[estado] || 0) + 1;
      }

      return acc;
    }, {});

    setUsuariosPorEstado(usersByState);
  };

  // Função para buscar a quantidade de erros
  const fetchQuantidadeErros = async () => {
    try {
      const dadosComProblemasRef = collection(db, "dadosComProblemas");
      const snapshot = await getDocs(dadosComProblemasRef);

      // Obtém a quantidade de documentos na coleção
      const quantidadeDocumentos = snapshot.size;

      setQuantidadeErros(quantidadeDocumentos);
    } catch (error) {
      console.error("Erro ao obter a quantidade de erros:", error);
    }
  };

  useEffect(() => {
    fetchUserCount();
    fetchQuantidadeFormulariosNaoPreenchidos();
    countUsuariosPorEstado();
    fetchQuantidadeErros();
  }, [usuarios]);

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbord">
        <div className="coluna1">
          <div className="containerAnalises">
            <div className="boxAnalise">
              <div className="boxTitleEIcon">
                <FaUser size={20} color="#fff" />
                <p>Usuários cadastrados</p>
              </div>

              <div className="boxAnaliseCenter">
                <span>{userCount}</span>
              </div>
            </div>

            <div className="boxAnalise orangeBox">
              <div className="boxTitleEIcon">
                <FaUser size={20} color="#fff" />
                <p>Preencheram formulário</p>
              </div>

              <div className="boxAnaliseCenter">
                <span>{quantideFormularioNaoPreenchido}</span>
              </div>
            </div>

            <div className="boxAnalise darkBox">
              <div className="boxTitleEIcon">
                <FaUser size={20} color="#fff" />
                <p>Dados com problemas</p>
              </div>
              <div className="boxAnaliseCenter">
                <span>{quantidadeErros}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="coluna2">
          <div className="boxAnaliseBig blueBox">
            <div className="boxTitleEIconBig">
              <FaUser size={20} color="#fff" />
              <p>Usuários por Estado</p>
            </div>

            <div className="boxAnaliseCenterBig">
              {Object.entries(usuariosPorEstado)
                .sort(([estadoA], [estadoB]) => estadoA.localeCompare(estadoB))
                .map(([estado, quantidade]) => (
                  <div key={estado} className="boxCidade">
                    <p>{estado}</p>
                    <span>{quantidade}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
