import "./styles.css";
import React, { useState, useEffect } from "react";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { db } from "../../services/conexaoFirebase";
import { collection, getDocs, doc, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import EstadosECidades from "../../Data/estados-e-cidades";

const BotaoNextPage = ({ currentPage, totalPages, setCurrentPage }) => {
  return (
    <div className="paginacao">
      <div className="areaBotoes">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="botaoPaginacao"
        >
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="botaoPaginacao"
        >
          Próxima
        </button>
      </div>
    </div>
  );
};

export default function BuscarPessoas() {
  const [usuarios, setUsuarios] = useState([]);
  const [cachedUsers, setCachedUsers] = useState({});

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [citiesInSelectedState, setCitiesInSelectedState] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 50;

  const [loading, setLoading] = useState(false);

  const handleStateChange = async (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setLoading(true);

    // Obtenha a lista de cidades para o estado selecionado
    const cities =
      EstadosECidades.estados.find((estado) => estado.sigla === selectedState)
        ?.cidades || [];

    // Defina as cidades no estado selecionado
    setCitiesInSelectedState(cities);

    // Selecione a primeira cidade por padrão
    setSelectedCity(cities.length > 0 ? cities[0] : "");

    // Aguarde um curto período de tempo simulando a busca (você pode remover isso e descomentar o restante do código)
    await new Promise((resolve) => setTimeout(resolve, 200));

    // Finaliza o carregamento
    setLoading(false);
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedCity(selectedCity);
  };

  const buscarUsuarios = async (page = 1) => {
    try {
      // Verifica se o estado e a cidade foram selecionados antes de realizar a busca
      if (selectedState && selectedCity) {
        const usersCollection = collection(db, "users");

        // Verifica se os usuários para o estado e cidade já estão em cache
        const cacheKey = `${selectedState}_${selectedCity}`;
        if (cachedUsers[cacheKey]) {
          const cachedUsersData = cachedUsers[cacheKey];
          const totalPages = Math.ceil(cachedUsersData.length / usersPerPage);

          setUsuarios(
            cachedUsersData.slice(
              (page - 1) * usersPerPage,
              page * usersPerPage
            )
          );
          setTotalPages(totalPages);
        } else {
          const querySnapshot = await getDocs(
            query(
              usersCollection,
              where("estado", "==", selectedState),
              where("cidade", "==", selectedCity)
            )
          );

          const users = [];
          // Itera sobre os documentos na coleção
          querySnapshot.forEach((doc) => {
            // Limita a lista de usuários com base na página e quantidade por página
            const userData = doc.data();
            if (
              userData.estado === selectedState &&
              userData.cidade === selectedCity
            ) {
              users.push({
                id: doc.id,
                name: userData.name,
                celular: userData.celular,
                email: userData.email,
                estado: userData.estado,
                cidade: userData.cidade,
                cep: userData.cep,
                situacao: userData.situacao,
              });
            }
          });

          // Atualiza o cache com os usuários encontrados
          setCachedUsers({
            ...cachedUsers,
            [cacheKey]: users,
          });

          const totalPages = Math.ceil(users.length / usersPerPage);
          setUsuarios(
            users.slice((page - 1) * usersPerPage, page * usersPerPage)
          );
          setTotalPages(totalPages);
        }
      }
    } catch (error) {
      console.error("Erro ao buscar usuários:", error.message);
      toast.error("Erro ao buscar usuários. Tente novamente mais tarde.");
    }
  };

  // Variável de estado para armazenar o número total de páginas
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setCurrentPage(1); // Reinicia a página ao selecionar um novo estado ou cidade
    buscarUsuarios();
  }, [selectedState, selectedCity]);

  useEffect(() => {
    buscarUsuarios(currentPage);
  }, [currentPage]);

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbordDash">
        <div className="depoimentos">
          <h3>Usuários cadastrados</h3>

          <div style={{ marginTop: 30 }}>
            <h3>Buscar por Estado e Cidade</h3>
            <div className="boxSelectFilter">
              <select
                className="selectFilterDepoimentos"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="" disabled>
                  Selecione o Estado
                </option>
                {EstadosECidades.estados.map((estado) => (
                  <option key={estado.sigla} value={estado.sigla}>
                    {estado.nome}
                  </option>
                ))}
              </select>

              {selectedState && (
                <select
                  className="selectFilterDepoimentos"
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  {citiesInSelectedState.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          {loading ? (
            <h3 style={{ paddingTop: 15, paddingBottom: 20 }}>
              Buscando dados...
            </h3>
          ) : usuarios.length === 0 ? (
            <h3 style={{ marginTop: 15 }}>Nenhum usuário encontrado!</h3>
          ) : (
            <div style={{ marginTop: 25 }}>
              <h3>{usuarios.length} Usuários encontrados!</h3>

              <BotaoNextPage
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />

              <div className="areaUsuarios">
                {selectedState && (
                  <>
                    {usuarios.map((user) => (
                      <div key={user.id} className="cardUsuario">
                        <p>
                          <strong>Nome: </strong> {user.name}
                        </p>

                        <p>
                          <strong>Celular: </strong>{" "}
                          <a
                            href={`https://wa.me/55${user.celular.replace(
                              /\D/g,
                              ""
                            )}`}
                            target="_blank"
                          >
                            {user.celular}
                          </a>
                        </p>
                        <p>
                          <strong>Email: </strong>
                          <a
                            href={`mailto:${user.email}?subject=Olá, somos do Rede Pública`}
                          >
                            {user.email}
                          </a>
                        </p>
                        <p>
                          <strong>Estado: </strong> {user.estado}
                        </p>
                        <p>
                          <strong>Cidade: </strong> {user.cidade}
                        </p>
                        <p>
                          <strong>CEP: </strong>{" "}
                          {user.cep ? user.cep : "CEP não cadastrado!"}
                        </p>
                        <p>
                          <strong>Situação: </strong> {user.situacao}
                        </p>
                      </div>
                    ))}
                  </>
                )}

                <p style={{ marginTop: 20, textAlign: "center" }}>
                  <strong>
                    {usuarios.length} usuários encontrados nessa página!
                  </strong>
                </p>
              </div>

              <BotaoNextPage
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
              />

              <br />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
