import "./styles.css";
import React, { useState, useEffect } from "react";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { db } from "../../services/conexaoFirebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export default function NovaVersaoApp() {
  const [versaoFirestore, setVersaoFirestore] = useState("");
  const [versoesDisponiveis, setVersoesDisponiveis] = useState([]);
  const [versaoSelecionada, setVersaoSelecionada] = useState("");

  // Função para gerar opções de versão com base na versão selecionada
  const gerarOpcoesVersao = (versaoSelecionada) => {
    // Array para armazenar as opções geradas
    const opcoes = [];

    // Dividindo a versão selecionada em partes principal e secundária
    const [principal, secundaria] = versaoSelecionada.split(".").map(Number);

    // Conjunto para garantir chaves únicas
    const keysSet = new Set();

    // Gerando 5 opções adicionais com base na versão selecionada
    for (let i = 1; i <= 5; i++) {
      // Calculando a nova versão secundária, limitada a 99
      const novaSecundaria = (secundaria + i) % 100;
      // Calculando a nova versão principal com base no overflow da versão secundária
      const novaPrincipal = principal + Math.floor((secundaria + i) / 100);
      // Combinando principal e secundária para formar a nova versão
      const novaVersao = `${novaPrincipal}.${novaSecundaria}`;
      // Criando uma chave única para identificar cada opção
      const novaChave = `${novaVersao}-${i}`;
      // Adicionando a chave ao conjunto para garantir unicidade
      keysSet.add(novaChave);

      // Adicionando a nova opção ao array
      opcoes.push(
        <option key={novaChave} value={novaVersao}>
          {novaVersao}
        </option>
      );
    }

    // Retornando o array de opções geradas
    return opcoes;
  };

  const handleInputChange = (event) => {
    const novaVersaoSelecionada = event.target.value;
    setVersaoSelecionada(novaVersaoSelecionada);
  };

  const handleAtualizarVersao = async () => {
    if (!versaoSelecionada || isNaN(parseFloat(versaoSelecionada))) {
      toast.error("Por favor, insira uma versão válida.");
      return;
    }

    if (versaoSelecionada === versaoFirestore) {
      toast.info("A versão já está atualizada.");
      return;
    }

    try {
      const versaoDocRef = doc(db, "versaoApp", "0");
      await updateDoc(versaoDocRef, { versao: versaoSelecionada });
      toast.success("Versão atualizada com sucesso!");
      setVersaoFirestore(versaoSelecionada);

      // Adicionar novas opções geradas com base na versão selecionada
      setVersoesDisponiveis((opcoesAntigas) => {
        // Adicionar apenas as novas opções geradas
        const novasOpcoes = gerarOpcoesVersao(versaoSelecionada);
        return [...novasOpcoes];
      });
    } catch (error) {
      console.error("Erro ao atualizar a versão no Firestore:", error);
      toast.error("Erro ao atualizar a versão. Tente novamente.");
    }
  };

  useEffect(() => {
    const buscarVersaoNoFirestore = async () => {
      try {
        const versaoDocRef = doc(db, "versaoApp", "0");
        const versaoDocSnap = await getDoc(versaoDocRef);

        if (versaoDocSnap.exists()) {
          const versao = versaoDocSnap.data().versao;
          setVersaoFirestore(versao);
          setVersaoSelecionada(versao);
          setVersoesDisponiveis(gerarOpcoesVersao(versao));
        }
      } catch (error) {
        console.error("Erro ao buscar a versão no Firestore:", error);
      }
    };

    buscarVersaoNoFirestore();
  }, []);

  return (
    <div className="containerAreaDashboard">
      <HeaderDashboard />
      <main className="contentDashbordDash">
        <div className="depoimentos">
          <div className="titleDepoimento">
            <h3>Versão do aplicativo!</h3>
          </div>

          <div className="containerAreaVersao">
            <div className="boxSelect">
              <label>Versão do aplicativo Android</label>
              <select value={versaoSelecionada} onChange={handleInputChange}>
                <option key={versaoFirestore} value={versaoFirestore}>
                  {`${versaoFirestore} - Versão atual`}
                </option>
                {versoesDisponiveis}
              </select>
            </div>

            <div>
              {versaoFirestore !== versaoSelecionada && (
                <button
                  className="buttonSendPush"
                  onClick={handleAtualizarVersao}
                >
                  Atualizar versão
                </button>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
