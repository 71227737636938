import "./styles.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  FiArrowRight,
  FiBell,
  FiFrown,
  FiActivity,
  FiMessageSquare,
  FiUsers,
} from "react-icons/fi";
import { MdPhoneAndroid } from "react-icons/md";
import { Context } from "../../Context/Auth";

export default function HeaderDashboard() {
  const { logOut } = useContext(Context);

  async function deslogar() {
    await logOut();
  }

  return (
    <div className="headerDashboard">
      <header>
        <ul>
          <li>
            <FiBell size={22} color="#fff" />
            <Link to="/notification-control-center">Enviar notificações</Link>
          </li>
          <li>
            <FiMessageSquare size={22} color="#fff" />
            <Link to="/depoimentos">Depoimentos</Link>
          </li>
          <li>
            <MdPhoneAndroid size={22} color="#fff" />
            <Link to="/nova-versao-aplicativo">Atualização aplicativo</Link>
          </li>
          <li>
            <FiUsers size={22} color="#fff" />
            <Link to="/buscar-pessoas">Buscar pessoas</Link>
          </li>
          <li>
            <FiFrown size={22} color="#fff" />
            <Link to="/dados-com-problemas">Dados com problemas</Link>
          </li>
          <li>
            <FiActivity size={22} color="#fff" />
            <Link to="/dash">Análises</Link>
          </li>
        </ul>
      </header>

      <div>
        <button className="buttonLogOut" onClick={deslogar}>
          Desconectar
        </button>
      </div>
    </div>
  );
}
